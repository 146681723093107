import React from 'react';
import RcSlider, { SliderTooltip } from 'rc-slider';
import PropTypes from 'prop-types';

const { Handle: SliderHandle } = RcSlider;

const Handle = ({
  value,
  dragging,
  index,
  tooltipStart,
  tooltipEnd,
  ...restProps
}) => (
  <SliderTooltip
    prefixCls="rc-slider-tooltip"
    overlay={`${100 - value}% ${tooltipStart}, ${value}% ${tooltipEnd}`}
    visible={dragging}
    placement="top"
    key={index}
    getTooltipContainer={(node) => node.parentNode}
  >
    <SliderHandle value={value} {...restProps} />
  </SliderTooltip>
);

Handle.propTypes = {
  value: PropTypes.number.isRequired,
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  tooltipStart: PropTypes.string.isRequired,
  tooltipEnd: PropTypes.string.isRequired,
};

export default Handle;
