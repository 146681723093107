/* eslint-disable react/prop-types */
import classNames from 'classnames';

import GenderIcon from 'assets/icons/gender.png';
import AgeIcon from 'assets/icons/age.png';
import EthnicityIcon from 'assets/icons/diversity.png';
import IncomeIcon from 'assets/icons/income.png';
import AudienceIcon from 'assets/icons/audience.png';
import CountryIcon from 'assets/icons/country.png';
import episodesPerMonthIcon from 'assets/icons/episodes-per-month.png';
import AdTypesIcon from 'assets/icons/ad-types.png';
import MaritalStatusIcon from 'assets/icons/marital-status.png';
import HostingIcon from 'assets/icons/hosting.png';
import HostingCapabilitiesIcon from 'assets/icons/hosting-capabilities.png';
import CPMFloorPriceIcon from 'assets/icons/cpm-floor-price.png';
import PreferredRateIcon from 'assets/icons/preferred-rate.png';
import isoToName from 'utils/functions/isoToName';
import adTypesOptions from 'utils/campaigns/adTypesOptions';
import adFormatsOptions from 'utils/campaigns/adFormatsOptions';
import ethnicityOptions from 'utils/campaigns/ethnicityOptions';
import formatNumberWithSuffix from 'utils/functions/formatNumberWithSuffix';
import maritalStatusOptions from 'utils/campaigns/maritalStatusOptions';
import incomeOptions from 'utils/campaigns/incomeOptions';
import { newAgeOptions } from 'utils/campaigns/ageOptions';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Size as ButtonSize,
  Kind as ButtonKind,
} from 'components/Common/Button';

import classes from 'components/Pages/Podcasts/PodcastFieldInformation/PodcastFieldInformation.module.scss';

const getPodcastFields = ({
  gender,
  ageRanges,
  country,
  adFormats,
  ethnicities,
  maritalStatuses,
  episodesPerMonth,
  incomeRanges,
  adTypes,
  audienceEstimate,
  downloadsEstimate,
  audienceLocation,
  approveProps: {
    showEditableOptions,
    onEdit,
    onEditDownloads,
    onEditLocation,
  },
  hostingProvider,
  hostingProviders,
  cpmFloorPrice,
  preferredRate,
}) => [
  {
    icon: GenderIcon,
    title: 'Gender',
    information: 'gender',
    canShowField: !!(gender?.female && gender?.male),
    Component: () => (
      <Body size={BodySize.XS} className={classes.informationText}>
        Male:&nbsp;
        {gender?.male || '0'}
        % &nbsp;
        <br />
        Female:&nbsp;
        {gender?.female || '0'}%
      </Body>
    ),
  },
  {
    icon: CountryIcon,
    title: 'Country',
    information: 'country',
    canShowField: !!country,
    Component: () => (
      <Body size={BodySize.XS} className={classes.informationText}>
        {country ? isoToName(country) : '-'}
      </Body>
    ),
  },
  {
    icon: AgeIcon,
    title: 'Age',
    information: 'ageRanges',
    canShowField: !!ageRanges?.length,
    Component: () => {
      const ageOptions = [];

      newAgeOptions.forEach(({ value, label }) => {
        const ageExists = ageRanges.find((ageRangeId) => ageRangeId === value);

        if (ageExists) {
          ageOptions.push(label);
        }
      });

      return (
        <div className={classes.badge}>
          {ageRanges?.length === 1 ? (
            <Body size={BodySize.XS} className={classes.informationText}>
              {ageOptions[0]}
            </Body>
          ) : (
            <Body size={BodySize.XS} className={classes.informationText}>
              <ul className={classes.unorderedList}>
                {ageOptions.map((ageRangeLabel) => (
                  <li key={ageRangeLabel}>{ageRangeLabel}</li>
                ))}
              </ul>
            </Body>
          )}
        </div>
      );
    },
  },
  {
    icon: AdTypesIcon,
    title: 'Ad Formats',
    information: 'adFormats',
    canShowField: !!adFormats?.length,
    Component: () => (
      <div className={classes.badge}>
        {adFormats?.length === 1 ? (
          <Body size={BodySize.XS} className={classes.informationText}>
            {
              adFormatsOptions.find(({ value }) => value === adFormats[0])
                ?.label
            }
          </Body>
        ) : (
          <Body size={BodySize.XS} className={classes.informationText}>
            <ul className={classes.unorderedList}>
              {adFormats.map((adFormatId) => (
                <li key={adFormatId}>
                  {
                    adFormatsOptions.find(({ value }) => value === adFormatId)
                      ?.label
                  }
                </li>
              ))}
            </ul>
          </Body>
        )}
      </div>
    ),
  },
  {
    icon: EthnicityIcon,
    title: 'Ethnicity',
    information: 'ethnicities',
    canShowField: !!ethnicities?.length,
    Component: () => (
      <div className={classes.badge}>
        {ethnicities?.length === 1 ? (
          <Body size={BodySize.XS} className={classes.informationText}>
            {
              ethnicityOptions.find(({ value }) => value === ethnicities[0])
                ?.label
            }
          </Body>
        ) : (
          <Body size={BodySize.XS} className={classes.informationText}>
            <ul className={classes.unorderedList}>
              {ethnicityOptions.map(({ value, label }) => {
                const ethnicityExists = ethnicities.find(
                  (ethnicityId) => ethnicityId === value
                );

                if (!ethnicityExists) {
                  return null;
                }

                return <li key={value}>{label}</li>;
              })}
            </ul>
          </Body>
        )}
      </div>
    ),
  },
  {
    icon: AdTypesIcon,
    title: 'Ad Types',
    information: 'adTypes',
    canShowField: !!adTypes?.length,
    Component: () => (
      <div className={classes.badge}>
        {adTypes?.length === 1 ? (
          <Body size={BodySize.XS} className={classes.informationText}>
            {adTypesOptions.find(({ value }) => value === adTypes[0])?.label}
          </Body>
        ) : (
          <Body size={BodySize.XS} className={classes.informationText}>
            <ul className={classes.unorderedList}>
              {adTypes.map((adTypesId) => (
                <li key={adTypesId}>
                  {
                    adTypesOptions.find(({ value }) => value === adTypesId)
                      ?.label
                  }
                </li>
              ))}
            </ul>
          </Body>
        )}
      </div>
    ),
  },
  {
    icon: MaritalStatusIcon,
    title: 'Marital Status',
    information: 'maritalStatuses',
    canShowField: !!maritalStatuses?.length,
    Component: () => (
      <div className={classes.badge}>
        {maritalStatuses?.length === 1 ? (
          <Body size={BodySize.XS} className={classes.informationText}>
            {
              maritalStatusOptions.find(
                ({ value }) => value === maritalStatuses[0]
              )?.label
            }
          </Body>
        ) : (
          <Body size={BodySize.XS} className={classes.informationText}>
            <ul className={classes.unorderedList}>
              {maritalStatuses.map((maritalStatusId) => (
                <li key={maritalStatusId}>
                  {
                    maritalStatusOptions.find(
                      ({ value }) => value === maritalStatusId
                    )?.label
                  }
                </li>
              ))}
            </ul>
          </Body>
        )}
      </div>
    ),
  },
  {
    icon: episodesPerMonthIcon,
    title: 'Episodes Per Month',
    information: 'episodesPerMonth',
    canShowField: typeof episodesPerMonth === 'number',
    Component: () => (
      <Body size={BodySize.XS} className={classes.informationText}>
        {episodesPerMonth}
      </Body>
    ),
  },
  {
    icon: IncomeIcon,
    title: 'Income',
    information: 'incomeRanges',
    canShowField: !!incomeRanges?.length,
    Component: () => {
      const currentIncomeOptions = [];

      incomeOptions.forEach(({ value, label }) => {
        const incomeExists = incomeRanges.find(
          (incomeRangeId) => incomeRangeId === value
        );

        if (incomeExists) {
          currentIncomeOptions.push(label);
        }
      });

      return (
        <div className={classes.badge}>
          {incomeRanges?.length === 1 ? (
            <Body size={BodySize.XS} className={classes.informationText}>
              {currentIncomeOptions[0]}
            </Body>
          ) : (
            <Body size={BodySize.XS} className={classes.informationText}>
              <ul className={classes.unorderedList}>
                {currentIncomeOptions.map((incomeRangeLabel) => (
                  <li key={incomeRangeLabel}>{incomeRangeLabel}</li>
                ))}
              </ul>
            </Body>
          )}
        </div>
      );
    },
  },
  {
    icon: AudienceIcon,
    title: 'Average Monthly Audience',
    information: 'audienceEstimate',
    altInformation: 'audienceEstimate',
    altSubInformation: 'screenshots',
    editable: true,
    editableInformation: 'screenshots',
    canShowField: true,
    Component: ({ editable }) => (
      <Body
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.editableWrapper)}
      >
        {formatNumberWithSuffix(audienceEstimate) || '-'}
        {showEditableOptions && editable && (
          <Button
            size={ButtonSize.S}
            kind={ButtonKind.Secondary}
            onClick={onEdit}
            className={classes.editButton}
          >
            Edit
          </Button>
        )}
      </Body>
    ),
  },
  {
    icon: AudienceIcon,
    title: 'U.S. Audience',
    information: 'audienceLocation',
    editable: true,
    canShowField: true,
    Component: ({ editable }) => (
      <Body
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.editableWrapper)}
      >
        {audienceLocation?.us ? `${audienceLocation?.us}%` : '-'}
        {showEditableOptions && editable && (
          <Button
            size={ButtonSize.S}
            kind={ButtonKind.Secondary}
            onClick={onEditLocation}
            className={classes.editButton}
          >
            Edit
          </Button>
        )}
      </Body>
    ),
  },
  {
    title: 'Hosting',
    information: 'hosting',
    canShowField: !!hostingProvider,
    icon: HostingIcon,
    Component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {hostingProvider}
      </Body>
    ),
  },
  {
    title: 'CPM Floor Price',
    information: 'cpmFloorPrice',
    canShowField: !!cpmFloorPrice,
    icon: CPMFloorPriceIcon,
    Component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        ${cpmFloorPrice}
      </Body>
    ),
  },
  {
    title: 'Preferred Rate',
    information: 'preferredRate',
    canShowField: !!preferredRate,
    icon: PreferredRateIcon,
    Component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        ${preferredRate}
      </Body>
    ),
  },
  {
    icon: AudienceIcon,
    title: 'Downloads Per Episode',
    information: 'downloadsEstimate',
    altInformation: 'downloadsEstimate',
    altSubInformation: 'screenshots',
    editable: true,
    editableInformation: 'screenshots',
    canShowField: true,
    Component: ({ editable }) => (
      <Body
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.editableWrapper)}
      >
        {formatNumberWithSuffix(downloadsEstimate) || '-'}
        {showEditableOptions && editable && (
          <Button
            size={ButtonSize.S}
            kind={ButtonKind.Secondary}
            onClick={onEditDownloads}
            className={classes.editButton}
          >
            Edit
          </Button>
        )}
      </Body>
    ),
  },
  {
    title: 'Hosting Capabilities',
    icon: HostingCapabilitiesIcon,
    canShowField:
      hostingProviders
        .find((elem) => elem.name === hostingProvider)
        ?.capabilities.filter(({ enabled }) => enabled).length > 0,
    Component: (props) =>
      hostingProviders.find((elem) => elem.name === hostingProvider)
        ?.capabilities.length > 0 ? (
        hostingProviders
          .find((elem) => elem.name === hostingProvider)
          .capabilities.map(
            ({ name, enabled, observations }) =>
              enabled && (
                <Body
                  {...props}
                  size={BodySize.XS}
                  className={classes.informationText}
                >
                  {observations.length > 0 ? `${name}: ${observations}` : name}
                </Body>
              )
          )
      ) : (
        <Body {...props} size={BodySize.XS}>
          -
        </Body>
      ),
  },
];

export default getPodcastFields;
