import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Spinner from 'components/Common/Spinner';
import Modal from 'components/Common/Modal';
import Confirmation from 'components/Common/Confirmation';
import PodcastDates from 'components/Pages/Podcasts/PodcastDates';
import Toast from 'components/Common/Toast';
import {
  selectEditPodcastDataState,
  selectFetchApprovedPodcastsState,
  selectRejectPodcastState,
  selectHiatusPodcastState,
  selectSendToNetworkPodcastState,
} from 'state/selectors/podcasts';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import {
  clearEditPendingDocumentState,
  clearEditPodcastDataState,
  clearPodcastsState,
  editPodcastData,
  fetchApprovedPodcasts,
  rejectPodcast,
  hiatusPodcast,
  sendToNetworkPodcast,
} from 'state/actions/podcasts';
import { fetchHostingProviders } from 'state/actions/generals';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalType.enum';
import SelectInput from 'components/Common/Select';
import Input from 'components/Common/Input';
import PodcastFullInformation from 'components/Pages/Podcasts/PodcastFullInformation';
import { sortingOptionsApprovedPodcasts } from 'utils/podcasts/getSortingOptions';
import getPodcastData from 'utils/podcasts/getPodcastData';
import filterPodcastsBySearchValue from 'utils/podcasts/filterPodcastsBySearchValue';
import EditPodcastAudienceEstimate from 'components/Pages/Podcasts/EditPodcastAudienceEstimate';
import EditPodcastDownloadsEstimate from 'components/Pages/Podcasts/EditPodcastDownloadsEstimate';
import EditPodcastAudienceLocation from 'components/Pages/Podcasts/EditPodcastAudienceLocation';

import classNames from 'classnames';
import networks from 'enums/podcasts/networks.enum';

import { sendToNetworkPodcastInit } from 'state/actionCreators/podcasts';
import classes from './ApprovedPodcasts.module.scss';

const ApprovedPodcasts = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    error: errorFetchingApprovedPodcasts,
    success: successFetchingApprovedPodcasts,
    loading: loadingFetchingApprovedPodcasts,
    approvedPodcasts,
  } = useSelector(selectFetchApprovedPodcastsState, shallowEqual);

  const {
    error: errorRejectPodcast,
    success: successRejectPodcast,
    loading: loadingRejectPodcast,
  } = useSelector(selectRejectPodcastState, shallowEqual);

  const {
    success: successHiatusPodcast,
    loading: loadingHiatusPodcast,
  } = useSelector(selectHiatusPodcastState, shallowEqual);

  const {
    error: errorSendToNetworkPodcast,
    success: successSendToNetworkPodcast,
    loading: loadingSendToNetworkPodcast,
  } = useSelector(selectSendToNetworkPodcastState, shallowEqual);

  const { success: successEditPodcastData } = useSelector(
    selectEditPodcastDataState,
    shallowEqual
  );

  const [podcastsToShow, setPodcastsToShow] = useState([]);
  const [podcastIdToDelete, setPodcastIdToDelete] = useState(null);
  const [selectedSort, setSelectedSort] = useState(
    sortingOptionsApprovedPodcasts[0]
  );
  const [searchValue, setSearchValue] = useState('');

  const {
    modal,
    setModal,
    onOpenModalHandler,
    onCloseModalHandler,
  } = useModal();

  useEffect(() => {
    if (user)
      dispatch(
        fetchApprovedPodcasts({ ...selectedSort, network: user.network })
      );

    return () => {
      dispatch(clearPodcastsState());
    };
  }, [selectedSort, user]);

  useEffect(() => {
    if (successEditPodcastData && user) {
      dispatch(
        fetchApprovedPodcasts({ ...selectedSort, network: user.network })
      );
    }
  }, [successEditPodcastData, selectedSort, user]);

  useEffect(() => {
    if (successHiatusPodcast) {
      dispatch(
        fetchApprovedPodcasts({ ...selectedSort, network: user.network })
      );
    }
  }, [successHiatusPodcast]);

  useEffect(() => {
    if (successFetchingApprovedPodcasts) {
      setPodcastsToShow(approvedPodcasts);
      dispatch(fetchHostingProviders());
      dispatch(clearPodcastsState());
    }
  }, [successFetchingApprovedPodcasts, approvedPodcasts]);

  useEffect(() => {
    if (successRejectPodcast) {
      onCloseModalHandler();
      setPodcastsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== podcastIdToDelete)
      );
      setPodcastIdToDelete(null);
    }
  }, [successRejectPodcast, podcastIdToDelete]);

  useEffect(() => {
    if (successSendToNetworkPodcast) {
      setPodcastsToShow((prevState) =>
        prevState.filter((doc) => doc.uid !== podcastIdToDelete)
      );
      onCloseModalHandler();
      dispatch(sendToNetworkPodcastInit());
    }
  }, [successSendToNetworkPodcast, loadingSendToNetworkPodcast]);

  const onClickRejectHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.REJECT_PODCAST, {
      podcastId: podcast.uid,
    });
  }, []);

  const onRejectPodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);
    dispatch(rejectPodcast({ podcastId: modal.podcastId }));
  }, [modal]);

  const onChangeSelectedSorting = (sort) => {
    setSelectedSort(sort);
    setSearchValue('');
  };

  const onChangeSearchValue = useCallback(({ target }) => {
    setSearchValue(target.value);
  }, []);

  const onLearnMoreHandler = useCallback((podcast) => {
    const podcastData = getPodcastData({ podcast });

    onOpenModalHandler(ModalType.PODCAST_SUMMARY, { podcast: podcastData });
  }, []);

  const onHiatusHandler = useCallback((podcast) => {
    onOpenModalHandler(ModalType.ON_HIATUS_PODCAST, { podcast });
  }, []);

  const onClickSendToNetwork = useCallback((podcast) => {
    onOpenModalHandler(ModalType.SEND_TO_NETWORK_PODCAST, {
      podcastId: podcast.uid,
      network:
        podcast.networks[0] === networks.Ossa
          ? networks.Spotify
          : networks.Ossa,
    });
  }, []);

  const onHiatusPodcastHandler = useCallback(async () => {
    await dispatch(
      hiatusPodcast({
        podcastId: modal.podcast.uid,
        status: !modal.podcast.isOnHiatus,
      })
    );
    onCloseModalHandler();
  }, [modal]);

  const onEditHandler = (podcast) => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_AUDIENCE, {
      podcast,
    });
  };

  const onEditDownloadsHandler = (podcast) => {
    onOpenModalHandler(ModalType.UPDATE_DOCUMENT_DOWNLOADS, {
      podcast,
    });
  };

  const onEditLocationHandler = (podcast) => {
    onOpenModalHandler(ModalType.UPDATE_AUDIENCE_LOCATION, {
      podcast,
    });
  };

  const onUpdateAudienceHandler = async ({ podcast, audienceEstimate }) => {
    dispatch(
      editPodcastData({
        podcastId: podcast.uid,
        editedData: { podcastData: { audienceEstimate } },
      })
    );

    setModal((prevState) => ({
      ...prevState,
      podcast: {
        ...prevState.podcast,
        podcastData: {
          ...prevState.podcast.podcastData,
          audienceEstimate,
        },
      },
    }));
  };

  const onEditDownloadsEstimateHandler = ({ podcast, downloadsEstimate }) => {
    dispatch(
      editPodcastData({
        podcastId: podcast.uid,
        editedData: { podcastData: { downloadsEstimate } },
      })
    );

    setModal((prevState) => ({
      ...prevState,
      podcast: {
        ...prevState.podcast,
        podcastData: {
          ...prevState.podcast.podcastData,
          downloadsEstimate,
        },
      },
    }));
  };

  const onUpdateAudienceLocationHandler = async ({ podcast, location }) => {
    dispatch(
      editPodcastData({
        podcastId: podcast.uid,
        editedData: { podcastData: { audienceInformation: { location } } },
      })
    );

    setModal((prevState) => ({
      ...prevState,
      podcast: {
        ...prevState.podcast,
        podcastData: {
          ...prevState.podcast.podcastData,
          location,
        },
      },
    }));
  };

  const onCancelAudienceHandler = ({ podcast }) => {
    onOpenModalHandler(ModalType.PODCAST_SUMMARY, { podcast });
  };

  const onCancelAudienceLocationHandler = () => {
    onCloseModalHandler();
  };

  const onSendToNetworkPodcastHandler = useCallback(() => {
    setPodcastIdToDelete(modal.podcastId);
    dispatch(
      sendToNetworkPodcast({
        podcastId: modal.podcastId,
        network: modal.network,
      })
    );
  }, [modal]);

  useEffect(() => {
    if (
      (modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE ||
        modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS ||
        modal.type === ModalType.UPDATE_AUDIENCE_LOCATION) &&
      successEditPodcastData
    ) {
      if (modal.type === ModalType.UPDATE_AUDIENCE_LOCATION) {
        onCancelAudienceLocationHandler();
        dispatch(clearEditPodcastDataState());
      } else {
        dispatch(clearEditPendingDocumentState());
        dispatch(clearEditPodcastDataState());
        onCancelAudienceHandler({
          podcast: modal.podcast,
        });
      }
    }
  }, [
    modal.type,
    modal.podcast,
    successEditPodcastData,
    onCancelAudienceHandler,
  ]);

  const searchedPodcasts = searchValue
    ? filterPodcastsBySearchValue(podcastsToShow, searchValue)
    : podcastsToShow;

  return (
    <>
      {errorFetchingApprovedPodcasts && (
        <Toast text={errorFetchingApprovedPodcasts} id="Fetch podcasts error" />
      )}
      {errorRejectPodcast && (
        <Toast text={errorRejectPodcast} id="Reject podcast error" />
      )}
      {errorSendToNetworkPodcast && (
        <Toast text={errorRejectPodcast} id="Send to network podcast error" />
      )}
      {successSendToNetworkPodcast && (
        <Toast
          type="success"
          text="The podcast has been transferred successfully!"
          id="Send to network podcast success"
        />
      )}
      <Modal
        isOpen={modal.type === ModalType.REJECT_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Reject podcast"
          description="Are you sure you want to reject the selected podcast?"
          onAccept={onRejectPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingRejectPodcast}
          acceptButtonText="Yes"
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_AUDIENCE}
        onClose={() => onCancelAudienceHandler({ podcast: modal.podcast })}
      >
        <EditPodcastAudienceEstimate
          isApproved
          podcast={modal.podcast}
          onUpdate={onUpdateAudienceHandler}
          onCancel={onCancelAudienceHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_DOCUMENT_DOWNLOADS}
        onClose={() => onCancelAudienceHandler({ podcast: modal.podcast })}
      >
        <EditPodcastDownloadsEstimate
          isApproved
          podcast={modal.podcast}
          onUpdate={onEditDownloadsEstimateHandler}
          onCancel={onCancelAudienceHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.UPDATE_AUDIENCE_LOCATION}
        onClose={() => onCancelAudienceLocationHandler()}
      >
        <EditPodcastAudienceLocation
          isApproved
          podcast={modal.podcast}
          onUpdate={onUpdateAudienceLocationHandler}
          onCancel={onCancelAudienceLocationHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_SUMMARY}
        onClose={onCloseModalHandler}
        className={classNames(
          classes.podcastModal,
          classes.podcastModalApproved
        )}
      >
        <PodcastFullInformation
          isApproved
          onEdit={onEditHandler}
          onEditDownloads={onEditDownloadsHandler}
          onEditLocation={onEditLocationHandler}
          podcast={modal.podcast}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.ON_HIATUS_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Change hiatus status"
          description="Are you sure you want to change the hiatus status of this podcast?"
          onAccept={onHiatusPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingHiatusPodcast}
          acceptButtonText="Yes"
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.SEND_TO_NETWORK_PODCAST}
        onClose={onCloseModalHandler}
      >
        <Confirmation
          title="Transfer podcast"
          description="Are you sure you want to transfer the selected podcast?"
          onAccept={onSendToNetworkPodcastHandler}
          onCancel={onCloseModalHandler}
          loading={loadingSendToNetworkPodcast}
        />
      </Modal>
      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Podcasts approved
          </Body>
          <div className={classes.manipulation}>
            <SelectInput
              options={sortingOptionsApprovedPodcasts}
              onChange={onChangeSelectedSorting}
              isOptionDisabled={(option) => option.value === selectedSort.value}
              value={selectedSort}
              className={classes.sort}
            />
            <Input
              className={classes.input}
              onChange={onChangeSearchValue}
              value={searchValue}
            />
          </div>
          {loadingFetchingApprovedPodcasts ? (
            <Spinner className={classes.spinner} />
          ) : (
            <>
              {searchedPodcasts.length === 0 ? (
                <Body
                  size={BodySize.XS}
                  spacing={BodySpacing.S}
                  className={classes.noPodcasts}
                >
                  There are no podcasts approved.
                </Body>
              ) : (
                <>
                  <div className={classes.podcasts}>
                    {searchedPodcasts.map((podcast) => {
                      const key = `podcast-${podcast.uid}`;
                      return (
                        <PodcastDates
                          key={key}
                          podcast={podcast}
                          onAction={() => onClickRejectHandler(podcast)}
                          learnMoreHandler={() => onLearnMoreHandler(podcast)}
                          hiatusHandler={() => onHiatusHandler(podcast)}
                          onSendToNetwork={() => onClickSendToNetwork(podcast)}
                          isAccepted
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ApprovedPodcasts;
