import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  selectEditPendingDocumentsPodcastsState,
  selectEditPodcastDataState,
} from 'state/selectors/podcasts';
import {
  clearEditPendingDocumentState,
  fetchPendingDocumentsPodcasts,
} from 'state/actions/podcasts';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Spinner, { Color as SpinnerColor } from 'components/Common/Spinner';
import Button, {
  Size as ButtonSize,
  Kind as ButtonKind,
  Color as ButtonColor,
} from 'components/Common/Button';
import Slider from 'components/Common/Slider';

import classes from './EditPodcastAudienceLocation.module.scss';

const EditPodcastAudienceLocation = ({
  podcast,
  onUpdate,
  // eslint-disable-next-line no-unused-vars
  isApproved,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const initialWorldPercentage = podcast?.podcastData?.audienceInformation
    ?.location?.world
    ? podcast?.podcastData?.audienceInformation?.location?.world
    : 30;

  const [worldPercentage, setWorldPercentage] = useState(
    initialWorldPercentage
  );

  const { loading, success } = useSelector(
    selectEditPendingDocumentsPodcastsState,
    shallowEqual
  );

  const { loading: loadingEditPodcastData } = useSelector(
    selectEditPodcastDataState,
    shallowEqual
  );

  useEffect(() => {
    setWorldPercentage(initialWorldPercentage);
  }, [initialWorldPercentage]);

  useEffect(() => {
    if (success) {
      dispatch(fetchPendingDocumentsPodcasts());
    }

    return () => {
      dispatch(clearEditPendingDocumentState());
    };
  }, [success, dispatch]);

  const onChangeAudienceHandler = (value) => {
    setWorldPercentage(value);
  };

  const onClickCancelHandler = () => onCancel();

  const onClickUpdateHandler = () => {
    const usPercentage = 100 - worldPercentage;
    onUpdate({
      podcast,
      location: {
        us: usPercentage,
        world: worldPercentage,
      },
    });
  };

  const isUpdating = loading || loadingEditPodcastData;

  return (
    <div className={classes.container}>
      {podcast?.podcastName && (
        <Body className={classes.title}>{podcast.podcastName}</Body>
      )}
      <img
        src={podcast?.podcastData?.imageUrl}
        alt="podcast"
        className={classes.image}
      />
      <Body size={BodySize.XS}>EDIT AUDIENCE LOCATION</Body>

      <Slider
        data-testid="slider"
        className={classes.slider}
        startText="U.S."
        endText="World"
        min={0}
        value={worldPercentage}
        max={100}
        step={1}
        marks={{ 50: '' }}
        onChange={onChangeAudienceHandler}
      />
      <div className={classes.buttonsContainer}>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          color={ButtonColor.Pink}
          className={classes.button}
          onClick={onClickCancelHandler}
          disabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.L}
          kind={ButtonKind.Primary}
          className={classes.button}
          disabled={isUpdating}
          onClick={onClickUpdateHandler}
        >
          {isUpdating ? <Spinner color={SpinnerColor.White} /> : 'Update'}
        </Button>
      </div>
    </div>
  );
};

EditPodcastAudienceLocation.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isApproved: PropTypes.bool,
  podcast: PropTypes.shape({
    uid: PropTypes.string,
    id: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      audienceEstimate: PropTypes.string,
      audienceInformation: PropTypes.shape({
        location: PropTypes.shape({
          world: PropTypes.number,
        }),
      }),
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
      ),
      webUrl: PropTypes.string,
      applePodcastUrl: PropTypes.string,
      ratingAverage: PropTypes.number,
    }),
    screenshots: PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      audienceEstimate: PropTypes.string,
      monthlyListeners: PropTypes.string,
    }),
  }),
};

EditPodcastAudienceLocation.defaultProps = {
  podcast: null,
  isApproved: false,
};

export default EditPodcastAudienceLocation;
